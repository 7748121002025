import { all, call, put, takeLatest } from "redux-saga/effects"
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import {
  GET_CANDIDATE_LIST_REQUEST,
  GET_CANDIDATE_COUNT_REQUEST,
  EXPORT_CANDIDATE_LIST_REQUEST
} from "./type"
// actions
import {
  getCandidateListSuccess,
  getCandidateListFailure,
  getCandidateCountSuccess,
  exportCandidateListSuccess
} from "./action"
import { downloadExcelFile } from "../../../../utils/commonFunction"
import { toast } from "react-hot-toast"

async function getCandidateListAPI(
  data = {
    page: 1,
    limit: 10,
    sortBy: "",
    sortDir: "desc",
    jobId: "",
    searchParam: "",
    allJobs: false
  }
) {
  const { page, limit, sortBy, sortDir, jobId, listType, searchParam, allJobs } = data
  const searchString = searchParam ? `&searchParams=${searchParam}` : ""
  const queryString = `?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDir=${sortDir}&jobid=${jobId}${searchString}&allJobs=${allJobs}`
  const listTypeToEndpoint = {
    "all-applications": `all-applications/${queryString}`,
    applied: `applicants/${queryString}&application_status=applied`,
    recommended: `applicants/${queryString}&application_status=recommended`,
    submitted: `applicants/${queryString}&application_status=submitted`,
    rejected: `applicants/${queryString}&application_status=rejected`,
    selected: `applicants/${queryString}&application_status=selected`,
    interviewing: `applicants/${queryString}&application_status=interviewing`,
    confirmed: `applicants/${queryString}&application_status=confirmed`,
    hired: `applicants/${queryString}&application_status=hired`,
    placed: `applicants/${queryString}&application_status=placed`
  }
  const endpoint = listTypeToEndpoint[listType]
  const URL = `${BASE_URL}api/v1/recruiter/${endpoint}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getCandidateListData({ data }) {
  try {
    const response = yield call(getCandidateListAPI, data)
    yield put(getCandidateListSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getCandidateListFailure(response?.data))
  }
}

async function getCandidateCountAPI(data) {
  const searchString = data?.searchParam
    ? `&searchParams=${data?.searchParam}`
    : ""
  const URL = `${BASE_URL}api/v1/recruiter/job-count/?jobid=${data?.jobId}${searchString}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getCandidateCount({ data }) {
  try {
    const response = yield call(getCandidateCountAPI, data)
    yield put(getCandidateCountSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getCandidateCountSuccess(response?.data))
  }
}

const exportCandidateListAPI = async data => {
  const URL = `${BASE_URL}api/v1/recruiter/export-candidates/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    responseType: "blob",
    data
  }

  return XHR(URL, options)
}

function* exportCandidateList({ data }) {
  try {
    const response = yield call(exportCandidateListAPI, data)
    yield put(exportCandidateListSuccess(response.data))
    const contentDispositionHeader = response.headers["content-disposition"]
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const filenameMatch = filenameRegex.exec(contentDispositionHeader)
    const filename = filenameMatch && filenameMatch[1].replace(/['"]/g, "")
    downloadExcelFile(response.data, {
      contentTypeHeader: response.headers["Content-Type"],
      fileName: filename || "candidate_list"
    })
  } catch (e) {
    const { response } = e

    yield put(exportCandidateListSuccess(response.data))
    toast.error(
      response?.data?.error
        ? response.data.error
        : "Some error occurred while downloading the resume."
    )
  }
}

export default all([
  takeLatest(GET_CANDIDATE_LIST_REQUEST, getCandidateListData),
  takeLatest(GET_CANDIDATE_COUNT_REQUEST, getCandidateCount),
  takeLatest(EXPORT_CANDIDATE_LIST_REQUEST, exportCandidateList)
])
