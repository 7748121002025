import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import React, { useCallback, useEffect, useRef, useState } from "react"
import USearch from "../../../components/USearch"
import DataTable from "../../../components/DataTable"
import { getAllCandidates } from "./redux/action"
import AvatarBadge from "../../../components/AvatarBadge"

import "./style.scss"

// Update endpoint and template to filter users by type
const AdminAllCandidates = props => {
  const {
    getAllCandidates,
    usersData,
    requesting
  } = props
  const history = useHistory()
  const { allCandidates } = usersData || {}
  const [searchParam, setSearchParam] = useState("")
  const { location } = history
  const pageInfo = useRef(window.history.state?.pageInfo?? {})

  const setPageInfoFromQueryParams = (pageInfo) => {
    const parsedQueryParams = new URLSearchParams(location?.search)
    pageInfo.current = {
      page: parseInt(parsedQueryParams.get('page')?? 1),
      limit: parseInt(parsedQueryParams.get('limit')?? 10),
      sortBy: parsedQueryParams.get('sortBy'),
      sortDir: parsedQueryParams.get('sortDir')?? 'asc',
      searchTerm: parsedQueryParams.get('searchTerm')
    }
  }

  setPageInfoFromQueryParams(pageInfo)

  useEffect(() => {
    setPageInfoFromQueryParams(pageInfo)
  }, [location?.search])

  const updateQueryParamsInUrl = (pageInfo) => {
    const queryParams = new URLSearchParams(window.location.search)
    pageInfo.current.page ? queryParams.set('page', pageInfo.current.page) : queryParams.delete('page')
    pageInfo.current.limit ? queryParams.set('limit', pageInfo.current.limit) : queryParams.delete('limit')
    pageInfo.current.sortBy ? queryParams.set('sortBy', pageInfo.current.sortBy) : queryParams.delete('sortBy')
    pageInfo.current.sortDir ? queryParams.set('sortDir', pageInfo.current.sortDir) : queryParams.delete('sortDir')
    pageInfo.current.searchTerm ? queryParams.set('searchTerm', pageInfo.current.searchTerm) : queryParams.delete('searchTerm')
    window.history.replaceState(null, null, "?"+queryParams.toString())
  }

  const goToUserProfile = rowData => () => {
    history.push(`/admin/candidate-user-profile/${rowData.user_id}`, {
      candidateData: {
        ...rowData,
        candidate_id: rowData.user_id,
        candidate_name: rowData.name1 || rowData.name2
      }
    })
  }

  const columns = [
    {
      id: "name1",
      label: "Candidate",
      width: "25%",
      sortable: true,
      renderColumn: rowData => {
        const name = rowData.name1 || rowData?.name2 || rowData.email
        return (
          <span
            onClick={goToUserProfile(rowData)}
            className={`user-title-label`}
          >
            <AvatarBadge name={name} />
            <span>
              {name}
            </span>
          </span>
        )
      }
    },
    {
      id: "email",
      label: "Email",
      width: "20%",
      sortable: true
    },
    {
      id: "phone",
      label: "Phone",
      width: "20%",
      sortable: true
    },
    {
      id: "location",
      label: "Location",
      width: "20%",
      sortable: false,
      renderColumn: rowData => {
        return (
          <span className={`user-location`}>
            {rowData.locations?.[0]?.formatted_address || "Not Available"}
          </span>
        )
      }
    }
  ]

  const getAllCandidatesData = useCallback(
    ({ page, limit, sortBy, sortDir, searchTerm }) => {
      pageInfo.current = { page, limit, searchTerm, sortBy, sortDir }
      getAllCandidates({
        page,
        limit,
        sortBy,
        sortDir,
        searchParam: searchTerm
      })
      updateQueryParamsInUrl(pageInfo)
    },
    [getAllCandidates]
  )

  const handleSearch = searchTerm => {
    setSearchParam(searchTerm)
  }

  return (
    <>
      <div className="admin-users-container">
        <div className="header">
          <div className="heading-div">
            <h3>Candidates</h3>
          </div>
          <USearch
            placeholder="Search for a candidate"
            onClick={handleSearch}
            searchRequesting={searchParam && requesting}
          />
        </div>
        <div className="admin-users-section">
          <DataTable
            columns={columns}
            data={requesting ? [] : allCandidates?.data}
            totalPages={allCandidates?.page?.total_page}
            getTableData={getAllCandidatesData}
            loading={requesting}
            searchTerm={searchParam}
            pageInfoInitialValue={pageInfo.current}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  usersData: state.adminUsers.usersData,
  requesting: state.adminUsers.requesting
})
const mapDispatchToProps = dispatch => ({
  getAllCandidates: data => dispatch(getAllCandidates(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminAllCandidates)
