import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Backdrop from "@mui/material/Backdrop"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import Popover from "@mui/material/Popover"
import CircularProgress from "@mui/material/CircularProgress"
import { connect } from "react-redux"
import dayjs from "dayjs"

import { Images } from "../../../theme/Images"
import Image from "../../../components/Image"
import {
  getAdminDashboardRequest,
  getAccountCreationActivityRequest,
  getLoginActivityRequest,
  getApplicationActivityRequest,
  getReviewActivityRequest,
  getAggregateCohortRequest
} from "./redux/action"

import "./style.scss"
import LineChart from "../../../components/LineChart"
//import LineAreaChart from "../../../components/LineAreaChart"
import UProgressbar from "../../../components/UProgressbar"
import DoughnutChart from "../../../components/DoughnutChart"
import PieChart from "../../../components/PieChart"
import LineBarChart from "../../../components/LineBarChart"
import {
  getAccountCreationChartData,
  getAggregateCohortChartData,
  getLoginActivityChartData,
  getReviewActivityChartData
} from "../../../utils/helper"
import ChartLegends from "./ChartLegends"
import {
  certificateCohortConf,
  currencyCohortConf,
  dateFilters,
  educationCohortConf,
  experienceCohortConf,
  locationCohortConf,
  onboardingCohortConf
} from "../../../utils/constants"
import UDatePicker from "../../../components/UDatePicker"
import Button from "../../../components/Button"

const AdminDashboard = props => {
  const {
    getAdminDashboardInfo,
    adminDashboardInfo,
    requesting,
    accountCreationActivityRequesting,
    accountCreationActivity,
    getAccountCreationActivityDispatch,
    loginActivityRequesting,
    loginActivity,
    getLoginActivityDispatch,
    applicationActivityRequesting,
    applicationActivity,
    getApplicationActivityDispatch,
    reviewActivityRequesting,
    reviewActivity,
    getReviewActivityDispatch,
    aggregateCohortRequesting,
    aggregateCohort,
    getAggregateCohortDispatch
  } = props
  const history = useHistory()
  const analyticsRequesting =
    requesting ||
    accountCreationActivityRequesting ||
    loginActivityRequesting ||
    applicationActivityRequesting ||
    reviewActivityRequesting

  const [showDateRange, setShowDateRange] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState("-6 months")
  const popoverOpen = Boolean(anchorEl)
  const popoverId = popoverOpen ? "simple-popover" : undefined
  const numberFormatter = new Intl.NumberFormat("en-UK")
  const [customDateRange, setCustomDateRange] = useState({})

  const [endDate, setEndDate] = useState(
    dayjs(new Date()).add(1, "day").format("YYYY-MM-DD")
  )
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(6, "months").format("YYYY-MM-DD")
  )
  const [locationCohort, locationCohortConfig] = getAggregateCohortChartData(
    aggregateCohort?.location_data || {},
    locationCohortConf
  )
  const [currencyCohort, currencyCohortConfig] = getAggregateCohortChartData(
    aggregateCohort?.currency_data || {},
    currencyCohortConf
  )
  const [educationCohort, educationCohortConfig] = getAggregateCohortChartData(
    aggregateCohort?.edu_data || {},
    educationCohortConf
  )
  const [experienceCohort, experienceCohortConfig] =
    getAggregateCohortChartData(
      aggregateCohort?.exp_data || {},
      experienceCohortConf
    )
  const [certificateCohort, certificateCohortConfig] =
    getAggregateCohortChartData(
      aggregateCohort?.cert_data || {},
      certificateCohortConf
    )
  const [onboardingCohort, onboardingCohortConfig] =
    getAggregateCohortChartData(
      aggregateCohort?.on_boarding_type || {},
      onboardingCohortConf
    )

  useEffect(() => {
    getAdminDashboardInfo()
  }, [getAdminDashboardInfo])

  useEffect(() => {
    getAccountCreationActivityDispatch({ startDate, endDate })
    getLoginActivityDispatch({ startDate, endDate })
    getApplicationActivityDispatch({ startDate, endDate })
    getReviewActivityDispatch({ startDate, endDate })
    getAggregateCohortDispatch({ startDate, endDate })
  }, [
    getAccountCreationActivityDispatch,
    getLoginActivityDispatch,
    getApplicationActivityDispatch,
    getReviewActivityDispatch,
    getAggregateCohortDispatch,
    startDate,
    endDate
  ])

  const handlePopoverClick = event => {
    setAnchorEl(event.currentTarget)
    setShowDateRange(false)
    setCustomDateRange({})
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const gotToPage = page => () => history.push(page)

  const handleFilterClick = (selected, unit, value) => () => {
    setSelectedFilter(selected)
    if (selected !== "custom-date") {
      const endDateValue = dayjs(new Date()).add(1, "day").format("YYYY-MM-DD")
      const startDateValue = dayjs(new Date())
        .subtract(value, unit)
        .format("YYYY-MM-DD")
      setStartDate(startDateValue)
      setEndDate(endDateValue)
      handlePopoverClose()
      setShowDateRange(false)
    } else {
      handlePopoverClose()
      setShowDateRange(true)
    }
  }

  const handleDateChange = field => value => {
    setCustomDateRange({ ...customDateRange, [field]: { value, error: "" } })
  }

  const submitCustomDate = () => {
    let customDateRangeValues = { ...customDateRange }
    if (!customDateRange?.startDate?.value) {
      customDateRangeValues = {
        ...customDateRange,
        startDate: {
          value: customDateRange?.startDate?.value,
          error: "This is required field"
        }
      }
    }
    if (!customDateRange?.endDate?.value) {
      customDateRangeValues = {
        ...customDateRangeValues,
        endDate: {
          value: customDateRange?.endDate?.value,
          error: "This is required field"
        }
      }
    }

    if (customDateRange?.endDate?.value && customDateRange?.startDate?.value) {
      if (
        customDateRange?.endDate?.value <= customDateRange?.startDate?.value
      ) {
        setCustomDateRange({
          ...customDateRangeValues,
          startDate: {
            value: customDateRange?.startDate?.value,
            error: "Start date must be earlier than end date"
          }
        })
        return false
      } else {
        setStartDate(customDateRange?.startDate?.value?.format("YYYY-MM-DD"))
        setEndDate(
          customDateRange?.endDate?.value?.add("1", "days").format("YYYY-MM-DD")
        )
        setCustomDateRange({})
        handlePopoverClose()
        setShowDateRange(false)
        return true
      }
    }

    setCustomDateRange(customDateRangeValues)
  }

  return (
    <>
      <div className="dashboard-container">
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={analyticsRequesting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="dashboard-count-row">
          <div
            className="dashboard-card users-card"
            onClick={gotToPage("/admin/all-users")}
          >
            <div className="card-icon">
              <Image src={Images.dashboardUsersIcon} />
            </div>
            <div className="card-content">
              <div className="count">
                {numberFormatter.format(adminDashboardInfo?.candidates || 0)}
              </div>
              <div className="info">Candidates</div>
            </div>
          </div>
          <div
            className="dashboard-card jobs-card"
            onClick={gotToPage("/admin/jobs/all_jobs")}
          >
            <div className="card-icon">
              <Image src={Images.dashboardJobsIcon} />
            </div>
            <div className="card-content">
              <div className="count">
                {numberFormatter.format(adminDashboardInfo?.jobs || 0)}
              </div>
              <div className="info">Open Jobs</div>
            </div>
          </div>
          <div
            className="dashboard-card teams-card"
            onClick={gotToPage("/admin/teams")}
          >
            <div className="card-icon">
              <Image src={Images.dashboardTeamsIcon} />
            </div>
            <div className="card-content">
              <div className="count">
                {numberFormatter.format(adminDashboardInfo?.teams || 0)}
              </div>
              <div className="info">All Teams</div>
            </div>
          </div>
        </div>
        <div className="analytics-section">
          <div className="title-row">
            <div className="title">Analytics Overview</div>
            <div className="filter">
              <FilterAltIcon
                onClick={handlePopoverClick}
                className="filter-icon"
              />
              <Popover
                id={popoverId}
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                className="filter-popover"
              >
                {dateFilters.map(({ key, label, unit, value }) => (
                  <div
                    className={`option ${
                      selectedFilter === key ? "active" : ""
                    }`}
                    key={key}
                    onClick={handleFilterClick(key, unit, value)}
                  >
                    {label}
                  </div>
                ))}
              </Popover>
            </div>
            <div className="selected-date-range">
              ({dayjs(startDate).format("MM-DD-YYYY")} -{" "}
              {dayjs(endDate).subtract("1", "days").format("MM-DD-YYYY")})
            </div>
          </div>
          {showDateRange && (
            <div className="date-range-row">
              <div className="date-fields-wrapper">
                <UDatePicker
                  placeholderValue={"Start Date"}
                  value={customDateRange?.startDate?.value || null}
                  onChange={handleDateChange("startDate")}
                  error={customDateRange?.startDate?.error}
                />
                <div
                  className={`error-message
            ${customDateRange?.startDate?.error ? "opacity-1" : ""}`}
                >
                  {customDateRange?.startDate?.error}
                </div>
              </div>
              <div className="date-fields-wrapper">
                <UDatePicker
                  placeholderValue={"End Date"}
                  value={customDateRange?.endDate?.value || null}
                  onChange={handleDateChange("endDate")}
                  error={customDateRange?.endDate?.error}
                />
                <div
                  className={`error-message
            ${customDateRange?.endDate?.error ? "opacity-1" : ""}`}
                >
                  {customDateRange?.endDate?.error}
                </div>
              </div>
              <Button title="Apply" onClick={submitCustomDate} />
            </div>
          )}
        </div>
        <div className="dashboard-chart-container">
          <div className="dashboard-chart-container-left">
            <div className="account-creation-chart">
              <div className="chart-header">
                <span className="title">Account creation date</span>
                <span className="legends">
                  <span className="legend-title account-creation-legend">
                    Account creation
                  </span>
                  <span className="legend-title profile-creation-legend">
                    Profile creation
                  </span>
                </span>
              </div>
              <div className="chart-area">
                <LineChart
                  datasets={getAccountCreationChartData(
                    accountCreationActivity
                  )}
                  loading={accountCreationActivityRequesting}
                />
              </div>
            </div>
            <div className="login-activity-chart">
              <div className="chart-header">
                <span className="title">Candidate Log in activity</span>
              </div>
              <div className="chart-area">
                <LineBarChart
                  datasets={getLoginActivityChartData(loginActivity)}
                  loading={loginActivityRequesting}
                />
              </div>
            </div>
            <div className="application-review-activity-row">
              <div className="application-activity-chart">
                <div className="chart-header">
                  <span className="title">Candidate application activity</span>
                </div>
                <div className="chart-area">
                  <div className="total-applicants-chart">
                    <div className="header-row">
                      <span className="title">Total applicants</span>
                      <span className="value">
                        {applicationActivity?.applied || 0}%
                      </span>
                    </div>
                    <UProgressbar
                      progressValue={applicationActivity?.applied || 0}
                      className="total-applicants-bar"
                      loading={applicationActivityRequesting}
                    />
                  </div>
                  <div className="invited-interview-chart">
                    <div className="header-row">
                      <span className="title">Invited interview</span>
                      <span className="value">
                        {applicationActivity?.interviewing || 0}%
                      </span>
                    </div>
                    <UProgressbar
                      progressValue={
                        applicationActivity?.interviewing || 0
                      }
                      loading={applicationActivityRequesting}
                      className="invited-interview-bar"
                    />
                  </div>
                  <div className="heired-chart">
                    <div className="header-row">
                      <span className="title">Hired</span>
                      <span className="value">
                        {applicationActivity?.hired || 0}%
                      </span>
                    </div>
                    <UProgressbar
                      progressValue={applicationActivity?.hired || 0}
                      loading={applicationActivityRequesting}
                      className="hired-bar"
                    />
                  </div>
                </div>
              </div>
              <div className="review-activity-chart">
                <div className="chart-header">
                  <span className="title">Candidate review activity</span>
                </div>
                <div className="chart-area">
                  <div className="chart-area-content">
                    <DoughnutChart
                      labels={["Unsubscribed users", "Inactive users"]}
                      datasets={getReviewActivityChartData(reviewActivity)}
                      loading={reviewActivityRequesting}
                    />
                  </div>
                  <div className="chart-footer">
                    <span className="legends">
                      <span className="legend-title unsubscribed-legend">
                        Unsubscribed users
                      </span>
                      <span className="legend-value">
                        {reviewActivity.unsubscribed || 0}%
                      </span>
                    </span>
                    <span className="legends">
                      <span className="legend-title inactive-legend">
                        Inactive users
                      </span>
                      <span className="legend-value">
                        {reviewActivity.inactive || 0}%
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-chart-container-right">
            <div className="aggregate-cohort-chart">
              <div className="chart-header">
                <span className="title">Aggregate cohort</span>
              </div>
              <div className="chart-area">
                <div className="location-chart">
                  <div className="chart-content">
                    <div className="chart-area-title">Location</div>
                    <div className="chart-area-body">
                      <PieChart
                        {...locationCohort}
                        loading={aggregateCohortRequesting}
                      />
                    </div>
                  </div>
                  <ChartLegends
                    legends={locationCohortConfig}
                    loading={aggregateCohortRequesting}
                  />
                </div>
                <div className="currency-chart">
                  <div className="chart-content">
                    <div className="chart-area-title">Currency</div>
                    <div className="chart-area-body">
                      <PieChart
                        {...currencyCohort}
                        loading={aggregateCohortRequesting}
                      />
                    </div>
                  </div>
                  <ChartLegends
                    legends={currencyCohortConfig}
                    loading={aggregateCohortRequesting}
                  />
                </div>
                <div className="onboarding-type-chart">
                  <div className="chart-content">
                    <div className="chart-area-title">Onboarding type</div>
                    <div className="chart-area-body">
                      <PieChart
                        {...onboardingCohort}
                        loading={aggregateCohortRequesting}
                      />
                    </div>
                  </div>
                  <ChartLegends
                    legends={onboardingCohortConfig}
                    loading={aggregateCohortRequesting}
                  />
                </div>
                <div className="previous-experience-chart">
                  <div className="chart-content">
                    <div className="chart-area-title">Previous Experience</div>
                    <div className="chart-area-body">
                      <PieChart
                        {...experienceCohort}
                        loading={aggregateCohortRequesting}
                      />
                    </div>
                  </div>
                  <ChartLegends
                    legends={experienceCohortConfig}
                    loading={aggregateCohortRequesting}
                  />
                </div>
                <div className="certification-chart">
                  <div className="chart-content">
                    <div className="chart-area-title">Certification</div>
                    <div className="chart-area-body">
                      <PieChart
                        {...certificateCohort}
                        loading={aggregateCohortRequesting}
                      />
                    </div>
                  </div>
                  <ChartLegends
                    legends={certificateCohortConfig}
                    loading={aggregateCohortRequesting}
                  />
                </div>
                <div className="education-chart">
                  <div className="chart-content">
                    <div className="chart-area-title">Education</div>
                    <div className="chart-area-body">
                      <PieChart
                        {...educationCohort}
                        loading={aggregateCohortRequesting}
                      />
                    </div>
                  </div>
                  <ChartLegends
                    legends={educationCohortConfig}
                    loading={aggregateCohortRequesting}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  adminDashboardInfo: state.adminDashboard.dashboardInfo,
  requesting: state.adminDashboard.requesting,
  error: state.adminDashboard.error,
  accountCreationActivity: state.adminDashboard.accountCreationActivity,
  accountCreationActivityRequesting:
    state.adminDashboard.accountCreationActivityRequesting,
  loginActivityRequesting: state.adminDashboard.loginActivityRequesting,
  loginActivity: state.adminDashboard.loginActivity,
  applicationActivityRequesting:
    state.adminDashboard.applicationActivityRequesting,
  applicationActivity: state.adminDashboard.applicationActivity,
  reviewActivityRequesting: state.adminDashboard.reviewActivityRequesting,
  reviewActivity: state.adminDashboard.reviewActivity,
  aggregateCohortRequesting: state.adminDashboard.aggregateCohortRequesting,
  aggregateCohort: state.adminDashboard.aggregateCohort
})

const mapDispatchToProps = dispatch => ({
  getAdminDashboardInfo: data => dispatch(getAdminDashboardRequest(data)),
  getAccountCreationActivityDispatch: data =>
    dispatch(getAccountCreationActivityRequest(data)),
  getLoginActivityDispatch: data => dispatch(getLoginActivityRequest(data)),
  getApplicationActivityDispatch: data =>
    dispatch(getApplicationActivityRequest(data)),
  getReviewActivityDispatch: data => dispatch(getReviewActivityRequest(data)),
  getAggregateCohortDispatch: data => dispatch(getAggregateCohortRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)
